<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="supply-line"
        navigation="supply-lines"
        title="Supply Line">
        <template v-slot="{ item, data }">

            <BT-Field-Select
                v-model="item.productID"
                :isEditing="data.isNew"
                itemText="productName"
                label="Product"
                navigation="products" />
                
            <BT-Field-Select
                v-model="item.departureLocationID"
                :isEditing="data.isEditing || data.isNew"
                itemText="locationName"
                label="Departure Location"
                navigation="locations" />
            
            <BT-Field-Select
                v-model="item.destinationLocationID"
                :isEditing="data.isEditing || data.isNew"
                itemText="locationName"
                label="Destination Location"
                navigation="locations" />

            <BT-Field-Select
                v-model="item.supplyAgreementID"
                :isEditing="data.isEditing || data.isNew"
                itemText="seller.companyName"
                label="Supplier"
                navigation="suppliers" />

            <BT-Field-Select
                v-model="item.supplyAgreementID"
                :isEditing="data.isEditing || data.isNew"
                itemText="seller.companyName"
                label="Supplier"
                navigation="suppliers" />

            <BT-Field-Number
                v-model.number="item.minimumOrderQuantity"
                label="Minimum Order Quantity"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Number
                v-model.number="item.quantityIncrement"
                label="Order In Increments Of"
                :isEditing="data.isEditing || data.isNew" />

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Supply-Line-Blade',
    props: {
        bladesData: null
    }
}
</script>